import React from 'react'
import { Link } from 'gatsby'
import { formatDate, convertUrlToInnerLink } from 'src/commonFunctions'

export default ({ title, num, date, url }) =>
  url ? (
    <Link className="chapter_card" to={convertUrlToInnerLink(url)}>
      <div className="content_info">
        <p className="count">{num}</p>
        <h3 className="title">{title}</h3>
        <p className="date">{`更新: ${formatDate(date)}`}</p>
      </div>
    </Link>
  ) : (
    <div className="chapter_card notice">
      <div className="content_info">
        <p className="count">{num}</p>
        <h3 className="title">{title}</h3>
        <p className="date">{`更新: ${formatDate(date)}`}</p>
      </div>
    </div>
  )
